import request from '~/common/utils/request'
export function checkToken (data) {
  return request({
    url: 'user/forgotPassword/checkToken',
    method: 'post',
    data
  })
}
export function changePass (data) {
  return request({
    url: 'user/forgotPassword',
    method: 'put',
    data
  })
}
export function changePassApp (data) {
  return request({
    url: 'user/forgotPassword/app',
    method: 'put',
    data
  })
}
