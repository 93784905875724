import request from '~/common/utils/request'

export function getListQuestion (id, token) {
  if (!token) {
    return request({
      url: '/guru/question/' + id,
      method: 'get'
    })
  } else {
    return request({
      url: '/guru/question/' + id,
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
}

export function getRemainQuestion () {
  return request({
    url: '/guru/remain-guru-question',
    method: 'get'
  })
}
