import requestColearn from '~/common/utils/requestColearn'

/**
 * Simple RESTful resource class
 */
class ResourceColearn {
  constructor (uri) {
    this.uri = uri
  }

  list (query = {}, token = null) {
    if (!token) {
      return requestColearn({
        url: '/' + this.uri,
        method: 'get',
        params: query
      })
    }
    return requestColearn({
      url: '/' + this.uri,
      method: 'get',
      params: query,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  get (id, token = null) {
    if (!token) {
      return requestColearn({
        url: '/' + this.uri + '/' + id,
        method: 'get'
      })
    }
    return requestColearn({
      url: '/' + this.uri + '/' + id,
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  store (resource) {
    return requestColearn({
      url: '/' + this.uri,
      method: 'post',
      data: resource
    })
  }

  update (id, resource) {
    return requestColearn({
      url: '/' + this.uri + '/' + id,
      method: 'put',
      data: resource
    })
  }

  destroy (id) {
    return requestColearn({
      url: '/' + this.uri + '/' + id,
      method: 'delete'
    })
  }

  put (resource) {
    return requestColearn({
      url: '/' + this.uri,
      method: 'put',
      data: resource
    })
  }

  patch (resource) {
    return requestColearn({
      url: '/' + this.uri,
      method: 'patch',
      data: resource
    })
  }

  syncQuestion (resource) {
    return requestColearn({
      url: '/' + this.uri,
      method: 'post',
      data: resource,
      headers: {
        Authorization: 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI4M2Y4ZWFkMy00YTBkLTRjM2YtYmEzMi1mMjhmNzhiYWRkZDMiLCJpYXQiOjE3MTAzMTg5MjMsImV4cCI6NDg2MzkxODkyM30.5zvKTw884EBSQscystZMEfud5MRfyCcvAxZ8z4p5qKtbKr1xnjtTXqKjxWVH8pCUvSJ3qXdB341UHbk-ws9KLQ'
      }
    })
  }
}

export {ResourceColearn as default}
