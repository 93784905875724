import axios from 'axios'
import {getToken, setToken} from './auth'

// Create axios instance
const service = axios.create({
  baseURL: process.env.NUXT_ENV_BASE_API,
  timeout: 10000 // Request timeout
})

// Request intercepter
service.interceptors.request.use(
  (config) => {
    config.headers['Cache-Control'] = 'no-store'
    const token = getToken()
    if (token) {
      config.headers.Authorization = 'Bearer ' + token // Set JWT token
    }
    return config
  },
  (error) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(error)
    }
    // Do something with request error
    Promise.reject(error)
  }
)

// response pre-processing
service.interceptors.response.use(
  (response) => {
    if (response.headers.authorization) {
      setToken(response.headers.authorization)
      response.data.token = response.headers.authorization
    }

    return response.data
  },
  (error) => {
    if (process.env.NODE_ENV !== 'production') {
      if (error.response) {
        const {
          url,
          method,
          params,
          headers
        } = error.response.config
        console.log({
          url,
          method,
          params,
          headers
        })
      } else {
        console.error(error.response)
      }
    }
    return Promise.reject(error)
  }
)

export default service
